'use strict';

/* exported
 couponEditCtrl
 */

function couponEditCtrl($scope, coupon, Restangular, $state, generateCode) {
  $scope.coupon = coupon || {
    activated: true,
    type: 'amount'
  };
  $scope.expirationType = coupon && !coupon.expiredAt ? 'number' : 'date';
  $scope.minimumPurchaseActivated = coupon && coupon.minimumPurchase > 0;
  $scope.unlimited = !$scope.coupon.limit;
  $scope.coupon.expiredAt = coupon && coupon.expiredAt && new Date(coupon.expiredAt);
  $scope.isNewCoupon = !coupon || !coupon._id;

  $scope.generateCode = function () {
    $scope.coupon.code = generateCode();
  };

  $scope.save = function() {

    $scope.couponForm.value.$setTouched();


    if ($scope.couponForm.code.$touched) {
      $scope.couponForm.code.$invalid = !new RegExp(/^([\w\d]){6,12}$/i).test($scope.couponForm.code.$modelValue);
      if ($scope.couponForm.code.$invalid) {
        return;
      }
    }

    if ($scope.couponForm.$invalid) {

      $scope.couponForm.code.$setTouched();
      $scope.couponForm.expiredAt.$setTouched();
      $scope.couponForm.expiredIn.$setTouched();
      $scope.couponForm.value.$setTouched();
      $scope.couponForm.type.$setTouched();

      return;
    }

    if ($scope.expirationType === 'date') {
      $scope.coupon.expiredIn = null;
    } else if ($scope.expirationType === 'number') {
      $scope.coupon.expiredAt = null;
    }

    if (!$scope.minimumPurchaseActivated) {
      $scope.coupon.minimumPurchase = null;
    }

    var promise = $scope.coupon._id ? $scope.coupon.put() : Restangular.all('coupons').post($scope.coupon);

    promise
      .then(function(coupon) {
        $scope.$emit('notify', {type: 'success', title: 'Create coupon', message: 'coupon created with Success'});
        $scope.coupon = coupon;
        $state.go('root.coupons');
      })
      .catch(function(err) {
        $scope.$emit('notify', {type: 'error', title: 'Create coupon', message: 'Failed to create coupon'});

        $scope.globalError = err.data.message;
      });

  };
}

'use strict';

/* exported
 couponCtrl
  */

function couponCtrl($scope, coupons, appConfig, $uibModal, $state ) {
  $scope.coupons = coupons;
  $scope.appConfig = appConfig;
  $scope.filters = {};
  $scope.total = coupons.meta.total;

  $scope.delete = function(coupon) {
    coupon.remove().then(function() {
      $scope.coupons = _.without($scope.coupons, coupon);
      $scope.$emit('notify', {type: 'success', title: 'Delete', message: 'Coupon deleted with Success'});
    });
  };

  /**
   * modal
   */

  $scope.copy = function(coupon) {
    $uibModal
      .open({
        templateUrl: 'views/coupons/copy-modal.html',
        controller: 'CouponCopyCtrl',
        resolve: {
          coupon: coupon
        }
      })
      .result
      .then(function(newCoupon) {
        console.log(newCoupon);
        $scope.coupons.push(newCoupon);
        $state.go('root.coupons');
      });
  };

  /**
   * filters
   */
  $scope.pageChanged = function() {
    var limit = 20;
    var offset = ($scope.currentPage - 1) * 20;

    $scope.filters.offset = offset;
    $scope.filters.limit = limit;

    $scope.coupons.getList($scope.filters).then(function(coupons) {
      $scope.coupons = coupons;
    });
  };

  $scope.$on('filters.status', function(event, data) {
    $scope.filters.activated = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.coupons.getList($scope.filters).then(function(coupons) {

      $scope.currentPage = 1;
      $scope.coupons = coupons;
      $scope.total = coupons.meta.total;

    });
  });

  $scope.$on('filters.code', function(event, data) {
    $scope.filters.code = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.coupons.getList($scope.filters).then(function(coupons) {

      $scope.currentPage = 1;
      $scope.coupons = coupons;
      $scope.total = coupons.meta.total;
    });
  });

  $scope.$on('filters.description', function(event, data) {
    $scope.filters.description = data;
    $scope.filters.offset = 0;
    $scope.filters.limit = 20;

    $scope.coupons.getList($scope.filters).then(function(coupons) {

      $scope.currentPage = 1;
      $scope.coupons = coupons;
      $scope.total = coupons.meta.total;
    });
  });
}

'use strict';

/* exported
  menuCouponsCtrl
 */
function menuCouponsCtrl($scope, $rootScope, $location) {
  $scope.filters = {};

  $scope.filterByStatus = function(status) {
    if ($scope.filters.status === status) {
      $scope.filters.status = null;
    } else {
      $scope.filters.status = status;
    }

    $rootScope.$broadcast('filters.status', $scope.filters.status);

    var search = $location.search();
    search.status = $scope.filters.status;
    $location.search(search);
  };

  $scope.$watch('filters.code', function(code) {
    $rootScope.$broadcast('filters.code', code);
  });

  $scope.$watch('filters.description', function(description) {
    $rootScope.$broadcast('filters.description', description);
  });
}

'use strict';

/* exported
couponCopyCtrl
 */

function couponCopyCtrl($scope, coupon, $uibModalInstance, Restangular, $state, generateCode) {

  var newCoupon = Object.assign({}, coupon);
  delete newCoupon._id;
  delete newCoupon.createdAt;
  delete newCoupon.code;
  delete newCoupon.usagesNumber;
  var originCode = coupon.code.toUpperCase();

  $scope.coupon = newCoupon;
  $scope.originCode = originCode;

  $scope.close = function() {
    $uibModalInstance
      .dismiss('closed');
  };

  $scope.generateCode = function () {
    $scope.coupon.code = generateCode();
  };

  $scope.copy = function() {
    var promise = Restangular.all('coupons').post($scope.coupon);

    if ($scope.copyForm.code.$touched) {
      $scope.copyForm.code.$invalid = !new RegExp(/^([\w\d]){6,12}$/i).test($scope.copyForm.code.$modelValue);
      if ($scope.copyForm.code.$invalid) {
        return;
      }
    }

    promise
      .then(function(coupon) {
        $scope.$emit('notify', {type: 'success', title: 'Create coupon', message: 'coupon created with Success'});
        $scope.coupon = coupon;
        $state.go('root.editCoupon', {couponId: coupon._id});
        $uibModalInstance.close(coupon);
      })
      .catch(function(err) {
        $scope.$emit('notify', {type: 'error', title: 'Create coupon', message: 'Failed to create coupon'});

        $scope.globalError = err.data.message;
      });

  };
}

'use strict';


/* exported
 generateCode
 */

function generateCode(){
    var length = 6 + Math.random() * 6; // code can be between 6 to 12 characters.
    var mask = 'abcdefghijklmnopqrstuvwxyz0123456789';
    var result = '';
    for (var i = 0; i < length; i++) {
      result += mask[Math.floor(Math.random() * mask.length)];
    }
    return result;
}

